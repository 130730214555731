import React, { useEffect } from "react";
import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import styles from "./newsletter.module.scss";
import { RouteComponentProps, useLocation } from "react-router-dom";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";

const NewsletterConfirm: React.FC<RouteComponentProps> = (props) => {
  React.useEffect(() => {
    amplitude.getInstance().logEvent("Show page: newsletter_confirm");
  }, []);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  React.useEffect(() => {
    const signupId = query.get("id");

    if (signupId != null && signupId.length > 30) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: '"' + encodeURI(signupId) + '"',
      };

      fetch("newsletter/confirm", requestOptions).then((d) => {
        // do nothing
      });
    }
  }, [query]);

  return (
    <Layout title="Nyhedsbrev">
      <Helmet>
        <title>Bekræft nyhedsbrev</title>
        <meta name="robots" content="nosnippet" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <SectionLayout altClassName={styles.newsletter}>
        <div className={styles.confirm}>
          <p>Tak, fordi du bekræftede din tilmelding 😊</p>
        </div>
      </SectionLayout>
    </Layout>
  );
};

export default NewsletterConfirm;
